(function ($, site) {
  Drupal.behaviors.basicNoderefV1 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      var signedIn = parseInt(site.userInfoCookie.getValue('signed_in'));
      var isLoyaltyMember = parseInt(site.userInfoCookie.getValue('is_loyalty_member')) ? 1 : 0;
      var userType = signedIn && isLoyaltyMember ? 'loyalty' : 'non_loyalty';

      $(context)
        .find('.js-loyalty-tier-offer.js-' + userType)
        .removeClass('hidden');
    }
  };
})(jQuery, window.site || {});
